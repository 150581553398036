:root {
  --main-color: #0e398f;
  --secondary-color: rgb(247, 183, 13);
}

::selection {
  color: #fff;
  background: var(--secondary-color);
}

.login-bg {
  position: relative;
}

.login-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .login-bg container hides overflow */
.login-bg {
  overflow: hidden;
}

.change-pw-bg {
  position: relative;
}

.change-pw-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/change-pw-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .change-pw-bg container hides overflow */
.change-pw-bg {
  overflow: hidden;
}

.create-ac-bg {
  position: relative;
}

.create-ac-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/create-ac-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .create-ac-bg container hides overflow */
.create-ac-bg {
  overflow: hidden;
}

.create-profile-bg {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
}

.create-profile-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/create-profile-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .create-profile-bg container hides overflow */
.create-profile-bg {
  overflow: hidden;
}

.forget-pw-bg {
  position: relative;
}

.forget-pw-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/forget-pw-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .forget-pw-bg container hides overflow */
.forget-pw-bg {
  overflow: hidden;
}

.add-bg-bg {
  position: relative;
}

.add-bg-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/add-bd-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: zoomInOut 10s infinite;
}

/* Ensure that the .add-bg-bg container hides overflow */
.add-bg-bg {
  overflow: hidden;
}

.formControlDropDown {
  margin: 0.5rem;
  width: 900px;
  background-color: white;
}

.liveFeedsHeader {
  position: fixed;
  top: 250px;
  right: 0;
  z-index: 999;
  width: 500px;
}

.verify-addr-bg::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("/public/assets/images/verify-addr-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Animations Styles */

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* Fade In Left Animation */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade In Right Animation */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade In Top Animation */
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade In Bottom Animation */
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animations to elements as needed */
.fade-in-left {
  animation: fadeInLeft 0.5s ease-out;
}

.fade-in-right {
  animation: fadeInRight 0.5s ease-out;
}

.fade-in-top {
  animation: fadeInTop 0.5s ease-out;
}

.fade-in-bottom {
  animation: fadeInBottom 0.5s ease-out;
}

/* Slide In Left Animation */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide In Right Animation */
@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide Out Right Animation */
@keyframes slideOutRight {
  from {
    opacity: 0;
    transform: translateX(0);
  }

  to {
    opacity: 1;
    transform: translateX(100%);
  }
}

/* Slide In Up Animation */
@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slide In Down Animation */
@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animations to elements as needed */
.slide-in-left {
  animation: slideInLeft 0.5s ease-out;
}

.slide-in-right {
  animation: slideInRight 0.5s ease-out;
}

.slide-out-right {
  animation: slideOutRight 0.5s ease-out;
}

.slide-in-up {
  animation: slideInUp 0.5s ease-out;
}

.slide-in-down {
  animation: slideInDown 0.5s ease-out;
}

/* Rise In Right Animation */
@keyframes riseInRight {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

/* Rise In Up Animation */
@keyframes riseInUp {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

/* Rise In Down Animation */
@keyframes riseInDown {
  from {
    opacity: 0;
    transform: translateX(50%) translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

/* Apply the animations to elements as needed */
.rise-in-left {
  transition: 0.4s;
}

.rise-in-right {
  animation: riseInRight 0.5s ease-out;
}

.rise-in-up {
  animation: riseInUp 0.5s ease-out;
}

.rise-in-down {
  animation: riseInDown 0.5s ease-out;
}

.txt-style-1 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.txt-style-2 {
  color: #fff;
  font-size: 46px;
  font-weight: 500;
  font-family: "DM Serif Display", serif;
  line-height: 3.2rem;
}

.txt-style-3 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 2rem;
  padding-block: 30px;
}

.txt-style-4 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 2rem;
  padding-block: 30px;
}

.txt-style-5 {
  color: #5e5e5e;
  font-size: 30px;
  font-weight: 500;
  line-height: 2rem;
  padding-block: 20px;
}

.txt-style-6 {
  color: #5e5e5e;
  font-size: 20px;
  font-weight: 400;
  line-height: 2rem;
  padding-block: 20px;
}

.txt-style-7 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  line-height: 2rem;
  padding-block: 40px;
}

.para-style-1 {
  font-size: 14px;
}

.donor-info {
  background-color: #efefef;
}

.btn-style-1 {
  background-color: var(--primary-color);
  font-weight: 700;
  transition: 0.4s;
}

.btn-style-1:hover {
  background-color: #091b3d;
  font-weight: 700;
}

.login-btn {
  background-color: #fff;
  color: var(--secondary-color);
  padding-inline: 40px;
  font-weight: 700;
  transition: 0.4s;
}

.login-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
  font-weight: 700;
}

.btn-style-2 {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 5px 4px 2px #d8d8d879;
  font-weight: 700;
  transition: 0.4s;
}

.btn-style-2:hover {
  background-color: #091b3d;
  color: #fff;
  font-weight: 700;
}

.btn-style-3 {
  background-color: var(--secondary-color);
  border-radius: 20px;
  color: #000;
  box-shadow: 0px 5px 4px 2px #d8d8d879;
  font-weight: 400;
  transition: 0.4s;
  border: 1px solid var(--secondary-color);
  font-family: "Poppins", sans-serif;
}

.btn-style-3:hover {
  background-color: #fff;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn-style-4 {
  background-color: var(--secondary-color);
  border-radius: 5px;
  color: #000;
  font-weight: 400;
  transition: 0.4s;
  border: 1px solid var(--secondary-color);
  font-family: "Poppins", sans-serif;
}

.btn-style-4:hover {
  background-color: #fff;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.rbc-time-slot {
  background-color: whitesmoke;
  color: #74a4c3;
}

/* .rbc-timeslot-group .rbc-time-slot{
  background-color: rgb(146, 105, 105);
} */
/* 
.rbc-timeslot-group .rbc-time-slot:hover{
  background-color: rgb(225, 199, 199);
} */

.rbc-time-slot:hover {
  background-color: rgb(240, 161, 161);
}

.rbc-allday-cell {
  display: none;
}
.rbc-allday-cell:hover {
  background-color: rgb(240, 161, 161);
}

.rbc-event,
.rbc-background-event {
  /* padding :0px !important; */
  border: none !important;
}
.rbc-time-content .rbc-time-column .rbc-timeslot-group .rbc-time-slot:hover {
  background-color: rgb(225, 199, 199) !important;
  z-index: 2;
}

/* .btnaddpost
{
  display: none;
  border: "2px solid #000";
  background-color : white;
  padding: "6px 10px";
  borderRadius: "4px";
  boxShadow: "2px 2px 4px gray";
} */
.rbc-event-label {
  display: none;
}

.rbc-time-content .rbc-time-column .rbc-timeslot-group .rbc-time-slot:hover {
  background-color: rgb(225, 199, 199) !important;
  z-index: 2;
  /* position: relative; Ensure it has positioning */
}

.rbc-events-container {
  width: 100%;
  pointer-events: auto; /* Allow pointer events */
  z-index: 1;
}

/* .rbc-events-container .rbc-current-time-indicator{
  width :100%;

   background-color:  red;

} */

.blurred-with-counter {
  position: relative; /* Allow absolute positioning of ::after */
  filter: blur(2px); /* Apply blur effect */
}

/* 
.blurred-with-counter::after {
  content: attr(data-counter);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(17, 16, 16);
} */

.image-wrapper {
  position: relative; /* Allow absolute positioning of the counter text */
  display: inline-block;
}

.counter-text {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 86%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Translate to center */
  font-size: 1.5rem; /* Adjust font size as desired */
  font-weight: bold; /* Use bold text */
  color: white; /* Text color for visibility */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: add a background color for better contrast */
  padding: 3.2rem 2.5rem; /* Optional: add padding around the text */
  border-radius: 0.25rem; /* Optional: add a border-radius */
  z-index: 1; /* Ensure it appears on top of the image */
}

.blurred-image {
  filter: blur(2px); /* Apply blur effect */
}

.video-reel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 10px;
  gap: 10px;
}

/* Video reel item styles */
.video-reel-item {
  flex: none;
  width: 250px; /* Adjust as needed */
  scroll-snap-align: start;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

.video-reel-item video {
  width: 100%;
  height: 400px;
  border-radius: 8px;
}

.video-reel-item p {
  margin-top: 8px;
  font-size: 14px;
}

.reel-view {
  width: 100%; /* Adjust this to the desired width of the reel view */
  height: 500px; /* Adjust this to the desired height of the reel view */
  overflow: hidden; /* Hide overflow content */
}

.video-container {
  width: 100%; /* Adjust as needed */
  height: 500px; /* Adjust as needed */
  position: relative; /* Make the container relative to position the overlay */
  overflow: hidden; /* Hide overflow content */
}

.channel-name-overlay {
  position: absolute; /* Positioning the overlay element absolutely */
  bottom: 30px; /* Align the overlay at the bottom */
  left: 0; /* Align the overlay at the left */
  padding: 5px; /* Add padding for a cleaner look */
  /* background-color: rgba(0, 0, 0, 0.5); */
  color: white; /* Text color */
  font-size: 14px; /* Font size for the text */

  align-items: center;
}

.text-value {
  display: block; /* Ensure block display */
  font-size: 14px; /* Adjust the font size if necessary */
  color: #ffffff; /* Adjust text color as needed */
  width: auto; /* Width can be adjusted as per requirement */
  line-height: normal; /* Adjust line height if needed */
  break-words: break-all; /* Allow words to break if necessary */
}

.channel-name-overlay img {
  margin-right: 8px; /* Add margin to the right of the image */
}

.channel-name-overlay span {
  font-weight: 600; /* Make the text bold */
  color: #fefefe; /* Adjust text color */
}
.channel-info {
  display: flex; /* Use Flexbox to align the items */
  align-items: center; /* Vertically center the items */
  margin-bottom: 4px; /* Add some margin below the channel info */
}

.text-value {
  font-size: 12px; /* Adjust font size as needed */
  color: #ffffff; /* Adjust text colo r as needed */
}

.reel-icons {
  position: absolute; /* Absolute positioning */
  bottom: 10px; /* 10px from the bottom */
  right: 10px; /* 10px from the right */
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Arrange icons vertically */
  gap: 10px; /* Space between icons */
  z-index: 10; /* Higher z-index to appear on top */
}

/* Icon button styles */
.icon-button {
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  color: white; /* Adjust color */
  font-size: 24px; /* Adjust size */
}

.btn-style-5 {
  background-color: #fff;
  border-radius: 5px;
  color: var(--primary-color);
  font-weight: 400;
  transition: 0.4s;
  border: 1px solid var(--primary-color);
  font-family: "Poppins", sans-serif;
}

.btn-style-5:hover {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

.checked\:bg-pink-500:checked {
  --tw-bg-opacity: 1;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checked\:before\:bg-pink-500:checked::before {
  background-color: var(--primary-color);
}

/* Scrollbar Styling */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

/* Verify Addr Styling */
.verify-addr {
  min-height: 120vh;
}

.verify-addr-form {
  background-color: #fff;
  padding: 60px 80px;
  border-radius: 20px;
  border-color: #606060;
  z-index: 999;
  width: 90%;
  margin: 0 auto;
}

/* 
.verify-addr-form::before {
  content: "";
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 30px;
  background-image: url("/public/assets/images/verify-addr-bg-box.png");
  background-repeat: no-repeat;
} */

.verify-addr-form Input {
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  border-radius: 10px;
  color: #606060;
}

/* Success profile styling */

.success-message {
  background-color: #fff;
  padding: 60px 120px;
  border-radius: 20px;
  border-color: #606060;
  z-index: 999;
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Loader Bg color */

.loader-bg-color {
  /* background-color: var(--secondary-color); */
  background: linear-gradient(
    -45deg,
    #f0b433,
    #f0b433,
    #f0b433,
    #e07801,
    #e07801,
    #e07801
  );
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.progress-bg-color {
  background-color: #5fcc00;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.primary-bg-color {
  background-color: var(--primary-color);
}

.primary-text-color {
  color: var(--primary-color);
}

.money-details-accordion:nth-child(2n + 1) {
  background-color: #efefef !important;
}

/* Landing page banner section styling */

.landing-page-banner-section {
  background-image: url("/public/assets/images/newLanding.png");
  min-height: 70vh;
  background-size: cover;
  padding-inline: 100px;
  background-position: 0px -35px;
}

.banner-text-1 {
  color: #000;
  font-size: 24px;
}

.banner-text-2 {
  color: #000;
  font-size: 4.6rem;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
}

.yellow-texture-bg {
  background-image: url("/public/assets/images/yellow-texture.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px 20px;
}

.counter-text-1 {
  position: relative;
  color: #000;
  font-size: 46px;
  padding-inline: 30px;
  font-family: "Poppins", sans-serif;
}

.counter-text-2 {
  position: relative;
  font-family: "Poppins", sans-serif;
}

.counter-text-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: -160px;
  right: 0;
  bottom: 0;
  background-image: url("/public/assets/images/yellow-blue-texture.png");
  background-size: 150px;
  background-repeat: no-repeat;
  z-index: -1;
  animation: fadeInRight 1s ease-out;
}

/* Icon Box Styling */
.icon-box {
  background-color: #f0f0f0;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: 0.4s;
}

.font-family-poppins {
  font-family: "Poppins", sans-serif;
}

.icon-box:hover {
  border: 1px solid var(--secondary-color);
}

/* Live feed section styling  */
.live-feed-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;
}

.donation-boxLiveFeed {
  /* width: 54%; */
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 20px;
}

.donation-description-boxLiveFeed {
  padding-inline: 11px;
  text-align: left;
}

.donation-description-boxLiveFeed .description-text {
  color: #000;
  padding-right: 14px;
  font-size: 15px;
}

.document-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

.donation-box {
  /* width: 400px; */
  background-color: #fff;
  border-radius: 20px;
  padding-bottom: 30px;
}

.donation-description-box {
  padding-inline: 25px;
  text-align: left;
}

.donation-description-box .description-text {
  color: #000;
  padding-right: 18px;
  font-size: 18px;
}

.donation-info-text {
  color: #0049b7;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
}

.donation-info-text-box {
  color: #0049b7;
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
}

.donation-info-numbers {
  color: #000;
}

.progress-bar-green {
  width: 100%;
  height: 10px;
  background-color: #fff;
  /* Set your desired background color */
  border-radius: 5px;
  border: 0.5px solid #5fcc00;
}

.progress-bar-fill-green {
  height: 100%;
  border-radius: 5px;
  background-color: #5fcc00;
}

.counter-overlay {
  background-color: var(--primary-color);
  border-radius: 20px;
  position: absolute;
  width: 100px;
  height: 100px;
}

.blue-box-text {
  line-height: 20px !important;
}

.orange-txt {
  color: var(--secondary-color);
}

.top-fundraiser-sec {
  background-image: url("/public/assets/images/fundraiser-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.top-footer-sec {
  background-color: #0f3796;
  color: #fff;
}

.footer {
  background-color: #062265;
  padding-block: 50px;
}

.bottom-footer {
  background-color: #00174d;
  padding-block: 20px;
}

.list-footer li {
  display: flex;
  gap: 10px;
}

.list-footer li:hover {
  color: var(--secondary-color) !important;
}

.excite-text {
  color: #ff0004;
}

/* Create Fundraiser Styling */

.create-fundraiser-sec {
  width: 100%;
}

.fundraiser-box {
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: 0.4s;
  border: 2px solid transparent;
  width: 400px;
  /* height: 6rem; */
}

.fundraiser-box img {
  border-radius: 10px;
  /* height: 10px; */
}

.fundraiser-box:hover {
  border: 2px solid var(--primary-color);
  background-color: rgba(233, 233, 233, 0.301);
  cursor: pointer;
}

.fundraiser-info-text {
  color: #0049b7;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
}

.fundraiser-info-numbers {
  color: #000;
}

/* Fundraiser for other form  */

.fundraiser-for-other-form {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}

.fundraiser-for-other-form label {
  width: 50%;
  font-size: 14px;
  margin-block: auto;
  font-weight: 500;
}

/* Fundraisr-sec */

.fundraiser-banner-section {
  background-image: url("/public/assets/images/fundraiser-bg.png");
  background-size: 100% 62%;
  background-repeat: no-repeat;
}
.recurring-banner-section {
  background-image: url("/public/assets/images/fundraiser-bg.png");
  background-size: 100% 68%;
  background-repeat: no-repeat;
}

.fundraiser-banner-section .slick-slide {
  margin-top: 20px;
  padding-block: 30px;
}
.recurring-banner-section .slick-slide {
  margin-top: 20px;
  padding-block: 30px;
}

.fundraiser-slider-box {
  width: 42rem;
  height: 40rem;
  object-fit: cover;
}

.recurring-slider-box {
  width: 42rem;
  height: 30rem;
  object-fit: cover;
}

.fundraiser-banner-section .slick-dots {
  position: absolute;
  bottom: 55px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  list-style: none;
  text-align: center;
}

.recurring-banner-section .slick-dots {
  position: absolute;
  bottom: 55px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  list-style: none;
  text-align: center;
}

.slick-dots li button:before {
  font-size: 18px !important;
  content: "〇" !important;
  color: var(--primary-color) !important;
}

.slick-dots li.slick-active button:before {
  font-size: 28px !important;
  content: "◉" !important;
  color: var(--primary-color) !important;
}

.fundraiser-banner-section .slick-arrow {
  display: none !important;
}

.recurring-banner-section .slick-arrow {
  display: none !important;
}

.share-fb-div {
  background-color: #3b5998;
  padding: 10px 20px;
  color: #fff;
  gap: 10px;
  transition: 0.4s;
}

.share-fb-div:hover {
  background-color: #243355;
  color: #fff;
  cursor: pointer;
}

.share-twitter-div {
  background-color: #00acee;
  padding: 10px 20px;
  color: #fff;
  gap: 15px;
  transition: 0.4s;
}

.share-twitter-div:hover {
  background-color: #03638a;
  color: #fff;
  cursor: pointer;
}

.slick-arrow {
  display: none !important;
}

/* About us Page Styling */

.about-page-banner-section {
  background-image: url("/public/assets/images/aboutUSNew.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.sidebar {
  background-color: #393939;
  border-top-right-radius: 30px;
}

.donationSidebar {
  background-color: #dee2e6;
  border-top-right-radius: 30px;
  height: 1300px;
}

.user-img-box {
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 3px 4px #0000002f;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.social-icon-box svg {
  filter: grayscale(50);
  transition: 0.2s;
  cursor: pointer;
}

.social-icon-box svg:hover {
  filter: grayscale(0);
}

.mantine-vl6xmj {
  z-index: 0 !important;
}

/* How We Work Styling */

.how-we-work-banner-section {
  background-image: url("/public/assets/images/HWWNew.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.how-we-work-modal-section {
  background-image: url("/public/assets/images/hww-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
}

.cust-height {
  height: 100vh; /* 80% of viewport height */
}

/* Mantine table Custom styling */
.verify-user .mantine-kq5u63 {
  display: none;
}

.verify-user thead th {
  background-color: #efefef !important;
}

.verify-user tbody tr:nth-child(even) td {
  background-color: #efefef !important;
}

.verify-user .mantine-a5v3r0 {
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

.mantine-Table-root {
}

.nav-item {
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
}

.active-link {
  color: var(--primary-color);
  padding-bottom: 10px;
  border-bottom: 3px solid var(--primary-color);
}

.after-arrow::after {
  content: url("/public/assets/images/arrow.svg");
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 35px;
  margin-top: -30px;
}

.after-arrow-hows::after {
  content: url("/public/assets/images/arrow.svg");
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 291px;
  margin-top: 23px;
}

.hww {
  background-color: #fafafa;
}

.contact-us-banner {
  background-image: url("/public/assets/images/support1.jpg");
  background-size: cover;
  background-position: center;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  margin-bottom: 10px;
}

.pagination-item {
  padding: 0 19px;
  height: 66px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 7.43;
  font-size: 19px;
  min-width: 34px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.pagination-item .arrow::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-item.disabled {
  pointer-events: none;
}

.pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-padding {
  padding: 1.2rem;
}

.custom-datepicker {
  font-size: 16px; /* Set the desired font size */
  width: 700px; /* Set the desired width */
}

.how-we-work-modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 1190px) {
  .landing-page-banner-section {
    background-image: url("/public/assets/images/landingPage.jpg");
    min-height: 50vh;
    background-size: cover;
    padding-inline: 50px;
    padding-top: 50px;
    background-position: center -35px;
    background-repeat: no-repeat;
  }

  .overlay {
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: auto;
  }
  .cust-height {
    height: 90vh;
  }

  .how-we-work-modal {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 1200px) {
  .liveFeedsHeader {
    display: none;
  }

  /* .overlay {
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); 
  }

  .cust-height{
    height: 50vh; 
  } */

  .how-we-work-modal-section {
    background-image: url("/public/assets/images/hww-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
  }
}

/* Responsive Styling for mobile screens */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .liveFeedsHeader {
    display: none;
  }

  .how-we-work-modal {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .overlay {
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
  }

  .cust-height {
    height: 80vh; /* 80% of viewport height */
  }

  .how-we-work-modal-section {
    background-image: url("/public/assets/images/hww-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
  }

  .landing-page-banner-section {
    background-image: url("/public/assets/images/landingPage.jpg");
    min-height: 50vh;
    background-size: cover;
    padding-inline: 20px;
    padding-top: 5px;
    background-position: center -45px;
    background-repeat: no-repeat;
  }

  .banner-text-1 {
    font-size: 16px;
  }

  .banner-text-2 {
    font-size: 2.4rem;
  }

  .btn-style-3 {
    font-size: 12px;
  }

  .counter-text-1 {
    font-size: 36px;
    padding-inline: 10px;
    padding-bottom: 10px;
  }

  .counter-text-2::before {
    left: -110px;
    background-size: 110px;
  }

  .txt-style-7 {
    font-size: 22px;
    padding-block: 20px;
  }

  .donation-description-box {
    padding-inline: 30px;
    text-align: left;
  }

  .btn-style-1 {
    font-size: 14px;
  }

  .txt-style-3 {
    color: #000;
    font-size: 22px !important;
    padding-block: 10px;
  }

  .truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .verify-addr-form {
    padding: 40px 20px;
    width: 97%;
    margin-top: 50px;
  }

  .success-message {
    background-color: #f1f1f1;
    padding: 30px;
    border-radius: 20px;
    border-color: #606060;
    z-index: 999999999 !important;
    width: 95%;
    margin-top: 50%;
    text-align: center;
  }

  .fundraiser-slider-box {
    width: 90%;
    height: 50%;
    margin-inline: auto;
    object-fit: cover;
  }
  .recurring-slider-box {
    width: 90%;
    height: 50%;
    margin-inline: auto;
    object-fit: cover;
  }

  .fundraiser-banner-section {
    background-size: 100% 84%;
  }

  .recurring-banner-section {
    background-size: 100% 84%;
  }

  .fundraiser-box {
    margin-inline: 20px;
  }

  .fundraiser-banner-section .slick-slide {
    padding-block: 10px;
  }

  .recurring-banner-section .slick-slide {
    padding-block: 10px;
  }

  .about-page-banner-section {
    padding-top: 40px;
    background-size: cover;
    background-position: center;
    height: 200px;
  }

  .sidebar {
    border-top-right-radius: 0px;
  }

  .donationSidebar {
    border-top-right-radius: 0px;
  }

  .how-we-work-banner-section {
    background-size: cover;
    padding-top: 50px;
    height: 380px;
  }

  .formControlDropDown {
    margin: 0.3rem;
    width: 300px;
    background-color: white;
  }

  /* *********************** */
  .pagination-container {
    display: flex;
    list-style-type: none;
  }

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
  }

  .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
  }

  .pagination-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  .pagination-item.selected {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .pagination-item .arrow::before {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
  }

  .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
  }

  .pagination-item .arrow.right {
    transform: rotate(45deg);
  }

  .pagination-item.disabled {
    pointer-events: none;
  }

  .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }

  .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
  }

  .pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .webkit-center {
    text-align: -webkit-center;
  }
}
.webkit-centerForAnalytics {
  text-align: -webkit-center;
}
