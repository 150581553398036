.media-container {
    display: flex !important;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    background-color: black;
    width: 100%;
    height: 300px; /* Default height for smaller screens */
  }
  
  .media-container img,
  .media-container video {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  
  @media (min-width: 768px) {
    .media-container {
      height: 500px; /* Height for medium and larger screens */
    }
  }
  